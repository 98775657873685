<template>
  <div class="content">
    <div v-html="protocol.content"></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      protocol: {}
    }
  },
  created(){
    this.getProtocol()
  },
  methods:{
    getProtocol(){
      this.$http.get(`/boom-center-config-service/sysAdmin/configuration/findConfigByParam`,{params:{
        configSet: 'distributionAgreement',
        module: 'article'
      }})
      .then(res=>{
        this.protocol = res.content
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content{
  padding: 20px;
}
</style>
